<template>
    <div>
       <v-data-table
            :headers="headers"
            :items="workEfforts"
            :loading=loadingTable
            :disable-sort="$vuetify.breakpoint.name == 'xs'"
            item-key="product_promo_code_id"
            :key="`list-admin-task-${tenantId}`"
            :options.sync="options"
            v-model=selected
            hide-default-footer
            disable-pagination
            show-select
            loading-text="Cargando cupones..."
            no-results-text="No se han encontrado tareas"
            no-data-text="Sin tareas"
            class="elevation-1"
            dense
        >
             
            <template v-slot:footer>
                <v-pagination
                    class='mt-10'
                    v-model=currentPage
                    :length=totalItems
                    @input="handlePageChange"
                    total-visible="15"
                ></v-pagination>
            </template>
        </v-data-table>
        <v-dialog 
            v-model="dialog"
            persistent 
            max-width="600px"
        >
            <v-card>
                <v-form ref='form_assign_work_effort'>
                    <v-col>
                        <v-radio-group v-model="assignType" row>
                            <v-radio
                                label="ASIGNAR A GRUPO"
                                @click="assignType = 'group'"
                                value="group"
                                :rules=required
                            />
                            <v-radio
                                label="ASIGNAR A UNA PERSONA"
                                value="individual"
                                @click="assignType ='individual'"
                                :rules=required
                            />
                        </v-radio-group>
                        <v-autocomplete
                            v-if="assignType=='individual'"
                            v-model='partySelected'
                            label="Usuarios"
                            :items=users
                            dense
                            outlined
                            item-value="party_id"
                            item-text="name"
                            :rules=required
                        />
                        <v-autocomplete
                            v-else
                            v-model='partySelected'
                            label="Grupos"
                            outlined
                            dense
                            :items=workGroups
                            item-value="party_id"
                            item-text="name"
                            :rules=required
                        />
                        <v-row>
                            <v-col class="text-center">
                                <v-btn
                                    color="primary"
                                    @click="storeAssignWorkEffort"
                                >
                                    <v-icon>mdi-content-save</v-icon> GUARDAR
                                </v-btn>
                                <v-btn 
                                    color='default'
                                    @click="dialog=false"
                                > 
                                    <v-icon>mdi-cancel</v-icon> Cerrar 
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-form>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>

import {mapState, mapMutations, mapActions} from 'vuex'
import Vue from 'vue'

export default {
    name: 'CuponesPromo',
    props:{        
        productPromoId: {           	
        	type: String,
            default: null
        },                 
    },
    data: ()=>({
        options:{},
        dataTable:[],
        setfilters:[],
        workEfforts:[],
        selected:[],
        users:[],
        workGroups:[],
        types:[],
        dialog:false,
        party_id:null,
        partySelected:null,
        workEffortId:null,
        type:null,
        totalItems:1,
        currentPage:1,
        itemsPerPage:20,
       
        assignType:'group',
        required:[v => !!v || 'El campo es requerido'],
       
        headers:[
            {text: 'ID', align: 'start', value: 'work_effort_id'},
            {text: 'Tipo', align: 'start', value: 'type'},
            {text: 'Proposito', align: 'start', value: 'purpose'},
            {text: 'Tarea', align: 'start', value: 'work_effort_name'},
            {text: 'Creada', align: 'start', value: 'created_stamp'},
            {text: 'Asignado a', align: 'start', value: 'assign'}
        ]
    }),
    watch:{
        options: {
            handler () {
                this.getAdminTask()
            },
            deep: true
        },
    },
    computed:{
        
        ...mapState('master',['loadingTable','tenantId','paramAlertQuestion'])

    },
    methods:{

        ...mapMutations('master',['setUrl','setLoadingTable','setTitleToolbar']),

        ...mapActions('master',['requestApi','alertNotification']),

        handlePageChange(value) {
            this.currentPage = value
            this.getAdminTask()
        },

        getCupon(id){
            
            /* if(typeof this.party_id !='undefined' && this.currentPage > 1)
                this.currentPage=1 */

            this.setLoadingTable(true)
            this.setUrl('cupon-promo/'+id)
            this.workEfforts=[]
            this.requestApi({
				method: 'GET',
				data: {
                    party_id : this.party_id,
                    page:this.currentPage,
                    itemsPerPage: this.itemsPerPage,
                    assignment: this.assignment,
                    type: this.type,
                    workEffortId: this.workEffortId
                }
			}).then(res =>{
                console.log(res)
                this.totalItems = res.data.page_count
                this.workEfforts = res.data._embedded.lista_tareas
                   
			}).then(()=>{
                this.setLoadingTable(false)
            })

        },

        

        guardarCuponPromo(){

            if(!this.$refs.form_assign_work_effort.validate())
                return false

            this.setUrl('lista-tareas')
            this.requestApi({
				method: 'POST',
				data: {
                    selecteds: this.selected,
                    partySelected: this.partySelected
                }
			}).then(res =>{
                console.log(res)
                this.alertNotification({
                    param:{
                        html: res.data.msg
                    }
                })   
                this.selected=[]
                this.getAdminTask()
                
			})

        },

        cargarCupones(){
            
            this.setUrl('cupon-promo')
            this.requestApi({
				method: 'GET',
				data: {}
			}).then(res =>{
                console.log(res)
                this.users = res.data._embedded.lista_tareas[0]
                this.types =res.data._embedded.lista_tareas[1]
			})
        }
    },
    mounted(){
        this.setTitleToolbar()
        this.cargarCupones()
    }
}
</script>
