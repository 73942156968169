<template>
  <div>
    <v-data-table :headers="headers" :items="dataTable" :items-per-page="itemsPerPage" :loading="loadingTable"
      :single-expand="true" :expanded.sync="expanded" :key="`list-promotion-${tenantId}`" :search="search"
      class="elevation-1" item-key="product_promo_id" show-expand dense>
      <template v-slot:top>
        <v-row align="center" justify="center">
          <v-col cols="12" md="3" class="text-left">
            <v-toolbar-title class="ml-md-4">
              <v-icon class="mb-1">mdi-sale</v-icon> PROMOCIONES
            </v-toolbar-title>
          </v-col>
          <v-col md="6">
            <v-text-field v-model="search" label="Buscar" />
          </v-col>
          <v-col md="3" class="text-center">
            <v-btn small color="primary" @click="editPromotion()">
              <v-icon>mdi-plus</v-icon> Nueva promoción
            </v-btn>
          </v-col>
        </v-row>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-btn icon @click="editPromotion(item)">
          <v-icon color="warning">mdi-pencil</v-icon>
        </v-btn>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-bind="attrs" v-on="on" class="mx-2" @click="deletePromotion(item)"
              color="red">mdi-delete-forever</v-icon>
          </template>
          <span>Eliminar promoción</span>
        </v-tooltip>
      </template>
      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length">
          <v-row>
            <v-col cols="12" class="my-2">
              <v-alert type="success" dense class="mb-0 py-1">
                Reglas
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn style="float: right;" v-bind="attrs" v-on="on" small elevation="2" icon
                      @click="editRule(item.product_promo_id)">
                      <v-icon>mdi-plus</v-icon>
                    </v-btn>
                  </template>
                  <span>Nueva regla</span>
                </v-tooltip>
              </v-alert>
              <v-simple-table dense>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">Nº</th>
                      <th class="text-left">Regla</th>
                      <th class="text-center">Acciones</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="rule in item.rules" :key="rule.name">
                      <td>{{ rule.product_promo_rule_id }}</td>
                      <td>{{ rule.rule_name }}</td>
                      <td class="text-center">
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon v-bind="attrs" v-on="on" @click="
                              editConditionAction(item.product_promo_id, rule)
                              " color="info">mdi-eye</v-icon>
                          </template>
                          <span>Ver condiciones y acciones</span>
                        </v-tooltip>
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon class="ml-2" v-bind="attrs" v-on="on" @click="editRule(item.product_promo_id, rule)"
                              color="warning">mdi-pencil</v-icon>
                          </template>
                          <span>Editar regla</span>
                        </v-tooltip>
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon class="ml-2" v-bind="attrs" v-on="on" @click="deleteRule(item.product_promo_id, rule)"
                              color="red">mdi-delete-forever</v-icon>
                          </template>
                          <span>Eliminar regla</span>
                        </v-tooltip>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
          </v-row>
        </td>
      </template>
    </v-data-table>
    <!-- dialog promotion -->
    <v-dialog v-model="dialogPromotion" fullscreen persistent background-color="white">
      <v-toolbar height="50" class="" color="blue" flat dark>
        <v-toolbar-title>PROMOCIÓN: {{ promotion.promo_text }}</v-toolbar-title>
        <v-divider class="mx-4" inset vertical> </v-divider>
        <v-spacer></v-spacer>
        <v-btn class="white--text" text small @click="closeDialogPromotion"><v-icon left>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-col>
        <v-col cols="12">
          <v-form ref="form_promotion">
            <v-row>
              <v-tabs v-model="tab" fixed-tabs>
                <v-tab>
                  PROMOCION
                </v-tab>
                <v-tab>
                  TIENDAS
                </v-tab>
                <v-tab>
                  CUPONES
                </v-tab>

                <!-- <v-tab >
                                        CONTENIDO
                                </v-tab> -->

                <v-tabs-items v-model="tab">
                  <v-tab-item class="mt-6 my-4 mx-4">
                    <v-row>
                      <v-col cols="12" md="9">
                        <v-text-field v-model="promotion.promo_name" type="text" label="Nombre de la promoción" dense
                          class="text-center pb-2" :rules="required"></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" md="9">
                        <v-text-field v-model="promotion.promo_text" type="text" label="Descripción" dense
                          class="text-center pb-2" :rules="required">
                          <!-- <template v-slot:append-outer>
                                                        <v-tooltip bottom>
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <v-icon 
                                                                @click="storePromotion"
                                                                v-bind="attrs"
                                                                v-on="on" color="success">
                                                                mdi-floppy
                                                            </v-icon>
                                                        </template>
                                                        <span>Guardar la promoción</span>
                                                        </v-tooltip>
                                                    </template> -->
                        </v-text-field>
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col cols="12" md="9">
                        <v-checkbox v-model="requiereCodigo" label="Requiere cupón">
                        </v-checkbox>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col>
                        <v-btn @click="storePromotion" small color="primary"><v-icon
                            left>mdi-content-save</v-icon>Guardar</v-btn>
                      </v-col>
                    </v-row>
                  </v-tab-item>
                  <v-tab-item class="mt-6 my-2 mx-4">
                    <v-row>
                      <v-col cols="12" md="12">
                        <v-data-table :headers="headersTiendas" :items="tiendasPromo" :loading="loadingTable"
                          v-if="promotion.product_promo_id" hide-default-footer class="elevation-1"
                          item-key="product_store_id" dense>
                          <template v-slot:top>
                            <v-row>
                              <v-col cols="12" md="12" class="text-left py-0">
                                <v-alert color="green" height="30" class="pt-1 white--text pb-6">
                                  TIENDAS DONDE APLICA ESTA PROMOCION
                                </v-alert>
                              </v-col>
                            </v-row>
                          </template>

                          <template v-slot:item.actions_tienda="{ item }">
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <v-icon @click="borrarTienda(item.product_store_id)" v-bind="attrs" v-on="on" color="red">
                                  mdi-delete
                                </v-icon>
                              </template>
                              <span>Eliminar la tienda</span>
                            </v-tooltip>
                          </template>
                        </v-data-table>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="3" md="3">
                        <v-select v-if="promotion.product_promo_id" id="productStoreId" class="md-12"
                          v-model="productStoreId" :items="productStores" item-text="store_name"
                          item-value="product_store_id" label="Seleccione una tienda"></v-select>
                      </v-col>
                      <v-col cols="3" md="3">
                        <v-text-field v-model="fechaDesde" clearable type="date" dense label="Fecha de inicio"
                          class="md-12 pt-4" />
                      </v-col>
                      <v-col cols="3" md="3">
                        <v-text-field v-model="fechaHasta" clearable type="date" dense label="Fecha de finalización"
                          class="md-12 pt-4" />
                      </v-col>
                      <v-col cols="3" md="3">
                        <v-btn v-if="promotion.product_promo_id" class="md-12 mt-5 primary" small
                          @click="agregarTienda()"><v-icon>mdi-plus</v-icon>Agregar a la tienda</v-btn>
                      </v-col>
                    </v-row>
                  </v-tab-item>

                  <v-tab-item class="mt-6">
                    <!-- <Cupones productPromoId="1000"></Cupones> -->
                    <v-toolbar color="#ececec" flat>
                      <v-toolbar-title>Crear cupones</v-toolbar-title>
                      <v-divider class="mx-4" inset vertical></v-divider>

                      <v-btn small color="green" dark @click="crearCupon"><v-icon>mdi-plus</v-icon>Crear
                        cupon</v-btn>
                    </v-toolbar>
                    <v-data-table :headers="headersCupones" :items="cuponesPromo" :items-per-page="itemsPerPageCupones"
                      :loading="loadingTable" :search="search" class="elevation-1" item-key="product_promo_code_id" dense>
                      <template v-slot:item.opciones_cupon="{ item }">
                        <v-btn small title="editar cupón" color="green" icon
                          @click="editarCupon(item)"><v-icon>mdi-pencil</v-icon></v-btn>
                        <v-btn small title="borrar cupón" color="red" icon
                          @click="borrarCupon(item)"><v-icon>mdi-delete</v-icon></v-btn>
                      </template>
                      <template v-slot:item.from_date="{ item }">
                        {{ item.from_date.substring(0, 10) }}
                      </template>
                      <template v-slot:item.thru_date="{ item }">
                        {{ item.thru_date != null ? item.thru_date.substring(0, 10) : '' }}
                      </template>
                    </v-data-table>
                  </v-tab-item>

                  <v-tab-item class="mt-6"> </v-tab-item>
                </v-tabs-items>
              </v-tabs>
            </v-row>
          </v-form>
        </v-col>
      </v-col>
    </v-dialog>
    <!-- fin dialog promotion -->

    <!-- dialog crear cupones -->
    <v-dialog v-model="dialogCrearCupones" max-width="480" persistent background-color="white">
      <v-form ref="formCupon">
        <v-col>
          <v-alert type="info" icon="mdi-dots-vertical" dense>
            CREAR CUPONES
          </v-alert>

          <v-row class="py-2">
            <v-col cols="5" md="5" class="py-0">
              <v-checkbox :disabled="cuponEdit == true" class="py-0" label="Ingreso manual"
                v-model="ingresoManual"></v-checkbox>
            </v-col>

            <v-col cols="6" md="6" class="py-0">
              <v-text-field v-if="ingresoManual == true" class="py-0 md-6" label="Numero del cupon"
                v-model="cupon.product_promo_code_id" :rules="required"></v-text-field>
              <v-text-field class="py-0" v-if="ingresoManual == false" label="Cantidad de cupones"
                title="Cantidad de cupones a crear" placeholder="numero del 1 al 500" v-model="cupon.cantidad_cupones"
                :rules="ruleMaxNumCupones"></v-text-field>
            </v-col>
          </v-row>
          <v-row class="py-0">
            <v-col cols="6" md="6" class="py-0">
              <v-checkbox md="6" cols="6" class="py-0" label="Ingresado por el usuario"
                v-model="ingresadoPorUusuario"></v-checkbox>
            </v-col>
            <v-col cols="6" md="6" class="py-0">
              <v-checkbox class="py-0" label="Confirmar código" v-model="cuponRequiereCodigo"></v-checkbox>
            </v-col>
          </v-row>

          <v-row class="py-0">
            <v-col cols="6" md="6" class="py-0">
              <v-text-field label="Límite por código" v-model="cupon.use_limit_per_code"></v-text-field>
            </v-col>
            <v-col md="6" cols="6" class="py-0">
              <v-text-field label=" Limite por cliente" v-model="cupon.use_limit_per_customer"></v-text-field>
            </v-col>
          </v-row>

          <v-row class="pt-4 mx-2">
            <v-col cols="12" md="12" class="py-0">
              <v-text-field class="py-0" label="Desde" type="date" v-model="cupon.from_date"
                :rules="required"></v-text-field>
            </v-col>
            <v-col cols="12" md="12" class="py-0">
              <v-text-field :rules="ruleFechaFinCupon" class="py-0 " label="Hasta" type="date"
                v-model="cupon.thru_date"></v-text-field>
            </v-col>
          </v-row>
        </v-col>

        <v-col>
          <v-btn color="green" small dark @click="guardarCupones" class="mx-2"><v-icon left
              small>mdi-content-save</v-icon>Guardar</v-btn>
          <v-btn text color="blue" small @click="dialogCrearCupones = false" class="mx-2"><v-icon left
              small>mdi-cancel</v-icon>Cancelar</v-btn>
        </v-col>
      </v-form>
    </v-dialog>
    <!-- dialog cupones -->

    <!-- dialog regla -->
    <v-dialog v-model="dialog" max-width="880" persistent background-color="white">
      <v-col>
        <v-alert type="info" icon="mdi-dots-vertical" dense>
          REGLA
        </v-alert>
        <v-col cols="12" md="6">
          <v-form ref="form_rule">
            <v-text-field v-model="rule.rule_name" type="text" label="Nombre de la regla" dense class="text-center pb-2"
              :rules="required">
              <template v-slot:append-outer>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon @click="storeRule" v-bind="attrs" v-on="on" color="success">
                      mdi-floppy
                    </v-icon>
                  </template>
                  <span>Guardar regla</span>
                </v-tooltip>
              </template>
            </v-text-field>
          </v-form>
        </v-col>
        <v-col class="text-center">
          <v-btn color="default" @click="closeRuleDialog">
            <v-icon>mdi-cancel</v-icon> Cerrar
          </v-btn>
        </v-col>
      </v-col>
    </v-dialog>
    <v-dialog v-model="dialogConditions" max-width="1200" width="1000" persistent>
      <v-col>
        <v-alert type="info" icon="mdi-dots-vertical" dense>
          CONDICIONES DE LA REGLA {{ rule.product_promo_rule_id }}x
          <div style="float:right">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-bind="attrs" v-on="on" @click="addConditionRule">mdi-plus</v-icon>
              </template>
              <span>Agregar condición</span>
            </v-tooltip>
          </div>
        </v-alert>
        <v-simple-table dense class="mb-4">
          <template v-slot:default>
            <thead>
              <tr>
                <th width="5%" class="text-left">Sequencia</th>
                <th width="30%" class="text-left">Condición</th>
                <th width="15%" class="text-left">Operador</th>
                <th width="15%" class="text-center">Valor</th>
                <th width="20%" class="text-center">Acciones</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="ppc in productPromoConditions" :key="ppc.name">
                <td>{{ ppc.product_promo_cond_seq_id }}</td>
                <td>
                  <v-select v-model="ppc.input_param_enum_id" :items="conditions" item-value="enum_id"
                    item-text="description" dense width="300px" :rules="required" />
                </td>
                <td class="text-left">
                  <v-select v-model="ppc.operator_enum_id" :items="operators" item-value="enum_id" item-text="description"
                    dense width="200px" :rules="required" />
                </td>
                <td class="text-center">
                  <v-text-field v-model="ppc.cond_value" type="text" dense class="text-center pb-2" :rules="required" />
                </td>
                <td class="text-center" style="width:200px">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon v-bind="attrs" v-on="on" class="mx-2" @click="storeConditionRule(ppc)"
                        color="success">mdi-floppy</v-icon>
                    </template>
                    <span>Guardar Condición</span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon v-bind="attrs" v-on="on" class="mx-2" @click="editProductsCondition(ppc)"
                        color="success">mdi-dropbox</v-icon>
                    </template>
                    <span>Agregar productos a la condición</span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon v-bind="attrs" v-on="on" class="mx-2" @click="editCategoriesCondition(ppc)"
                        color="success">mdi-view-dashboard</v-icon>
                    </template>
                    <span>Agregar categorías a la condición</span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon v-bind="attrs" v-on="on" class="mx-2" @click="deleteConditionRule(ppc)"
                        color="red">mdi-delete-forever</v-icon>
                    </template>
                    <span>Eliminar condición</span>
                  </v-tooltip>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        <div>
          <v-alert type="info" icon="mdi-dots-vertical" dense>
            ACCIONES DE LA REGLA {{ rule.product_promo_rule_id }}
            <div style="float:right">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on" @click="addActionRule">mdi-plus</v-icon>
                </template>
                <span>Agregar acciones</span>
              </v-tooltip>
            </div>
          </v-alert>
          <v-simple-table dense>
            <template v-slot:default>
              <thead>
                <tr>
                  <th width="5%" class="text-left">N°</th>
                  <th width="30%" class="text-left">Acción</th>
                  <th width="10%" class="text-left">Cantidad</th>
                  <th width="10%" class="text-left">Importe</th>
                  <th width="25%" class="text-left">Producto</th>
                  <th width="20%" class="text-center">Acciones</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(ppa, x) in productPromoActions" :key="x">
                  <td>{{ ppa.product_promo_action_seq_id }}</td>
                  <td>
                    <v-autocomplete v-model="ppa.product_promo_action_enum_id" :items="actions" item-value="enum_id"
                      item-text="description" dense hide-details width="250px" />
                  </td>
                  <td>
                    <v-text-field v-model="ppa.quantity" type="number" dense class="text-center pb-2" hide-details
                      :rules="requiredMin" />
                  </td>
                  <td>
                    <v-text-field v-model="ppa.amount" type="number" dense class="text-center pb-2" hide-details
                      :rules="requiredMin" />
                  </td>
                  <td class="text-left">
                    <v-autocomplete v-model="ppa.product_id" :items="products" item-value="product_id"
                      item-text="internal_name" dense hide-details width="250px" />
                  </td>
                  <td class="text-center" style="width:200px">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon v-bind="attrs" v-on="on" class="mx-2" @click="storeActionRule(ppa)"
                          color="success">mdi-floppy</v-icon>
                      </template>
                      <span>Guardar acción</span>
                    </v-tooltip>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon v-bind="attrs" v-on="on" class="mx-2" @click="editProductsActions(ppa)"
                          color="success">mdi-dropbox</v-icon>
                      </template>
                      <span>Agregar productos a la acción</span>
                    </v-tooltip>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon v-bind="attrs" v-on="on" class="mx-2" @click="editCategoriesAction(ppa)"
                          color="success">mdi-view-dashboard</v-icon>
                      </template>
                      <span>Agregar categorías a la acción</span>
                    </v-tooltip>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon v-bind="attrs" v-on="on" class="mx-2" @click="deleteActionRule(ppa)"
                          color="red">mdi-delete-forever</v-icon>
                      </template>
                      <span>Eliminar acción</span>
                    </v-tooltip>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </div>
        <v-col class="text-center">
          <v-btn color="default" @click="closeConditionDialog">
            <v-icon>mdi-cancel</v-icon> Cerrar
          </v-btn>
        </v-col>
      </v-col>
    </v-dialog>
    <v-dialog v-model="dialogConditionProductCondition" max-width="1000" persistent background-color="white">
      <v-col>
        <v-alert type="info" icon="mdi-dots-vertical" dense>
          Condiciones de productos
          <div style="float:right">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-bind="attrs" v-on="on" class="mx-2" @click="addProductCondition()">mdi-plus</v-icon>
              </template>
              <span>Agregar condiciones de productos</span>
            </v-tooltip>
          </div>
        </v-alert>
        <v-form ref="form_condition_product">
          <v-row>
            <v-col v-for="(pc, x) in productsContdition" :key="x" cols="6">
              <v-col cols="12">
                <v-row>
                  <v-col cols="6">
                    <v-autocomplete v-model="pc.new_product_id" :items="products" label="Producto" :rules="required"
                      item-value="product_id" item-text="internal_name" dense />
                  </v-col>
                  <v-col cols="6">
                    <v-autocomplete v-model="pc.product_promo_appl_enum_id" :items="productConditions" label="Condición"
                      :rules="required" item-value="enum_id" item-text="description" dense>
                      <template v-slot:append-outer>
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon v-bind="attrs" v-on="on" class="mx-2" color="success"
                              @click="storeProductCondition(pc)">
                              mdi-floppy
                            </v-icon>
                          </template>
                          <span>Guardar condición de producto</span>
                        </v-tooltip>
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon v-bind="attrs" v-on="on" class="mx-2" color="red"
                              @click="deleteProductCondition(pc)">mdi-delete-forever</v-icon>
                          </template>
                          <span>Eliminar condición de producto</span>
                        </v-tooltip>
                      </template>
                    </v-autocomplete>
                  </v-col>
                </v-row>
              </v-col>
            </v-col>
          </v-row>
        </v-form>
        <v-col class="text-center">
          <v-btn color="default" @click="closeConditionProductsDialog">
            <v-icon>mdi-cancel</v-icon> Cerrar
          </v-btn>
        </v-col>
      </v-col>
    </v-dialog>
    <v-dialog v-model="dialogConditionCategoryCondition" max-width="1000" persistent background-color="white">
      <v-col>
        <v-alert type="info" icon="mdi-dots-vertical" dense>
          Condiciones de categorías
          <div style="float:right">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-bind="attrs" v-on="on" class="mx-2" @click="addCategoriesCondition">mdi-plus</v-icon>
              </template>
              <span>Agregar condiciones de categorías</span>
            </v-tooltip>
          </div>
        </v-alert>
        <v-form ref="form_condition_category">
          <v-row>
            <v-col v-for="(cc, x) in categoriesCondition" :key="x" cols="6">
              <v-col cols="12">
                <v-row>
                  <v-col cols="6">
                    <v-autocomplete v-model="cc.new_product_category_id" :items="categories" label="Categoría"
                      :rules="required" item-value="product_category_id" item-text="description" dense />
                  </v-col>
                  <v-col cols="6">
                    <v-autocomplete v-model="cc.product_promo_appl_enum_id" :items="categoryConditions" label="Condición"
                      :rules="required" item-value="enum_id" item-text="description" dense>
                      <template v-slot:append-outer>
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon v-bind="attrs" v-on="on" class="mx-2" color="success"
                              @click="storeCategoryCondition(cc)">
                              mdi-floppy
                            </v-icon>
                          </template>
                          <span>Guardar condición de categoría</span>
                        </v-tooltip>
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon v-bind="attrs" v-on="on" class="mx-2" color="red"
                              @click="deleteCategoryCondition(cc)">mdi-delete-forever</v-icon>
                          </template>
                          <span>Eliminar condición de categoría</span>
                        </v-tooltip>
                      </template>
                    </v-autocomplete>
                  </v-col>
                </v-row>
              </v-col>
            </v-col>
          </v-row>
        </v-form>
        <v-col class="text-center">
          <v-btn color="default" @click="closeConditionCategoryDialog">
            <v-icon>mdi-cancel</v-icon> Cerrar
          </v-btn>
        </v-col>
      </v-col>
    </v-dialog>
    <v-dialog v-model="dialogConditionProductAction" max-width="1000" persistent background-color="white">
      <v-col>
        <v-alert type="info" icon="mdi-dots-vertical" dense>
          Condiciones de productos
          <div style="float:right">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-bind="attrs" v-on="on" class="mx-2" @click="addProductConditionAction()">mdi-plus</v-icon>
              </template>
              <span>Agregar condiciones de productos</span>
            </v-tooltip>
          </div>
        </v-alert>
        <v-form ref="form_condition_product">
          <v-row>
            <v-col v-for="(pc, x) in productsContdition" :key="x" cols="6">
              <v-col cols="12">
                <v-row>
                  <v-col cols="6">
                    <v-autocomplete v-model="pc.new_product_id" :items="products" label="Producto" :rules="required"
                      item-value="product_id" item-text="internal_name" dense />
                  </v-col>
                  <v-col cols="6">
                    <v-autocomplete v-model="pc.product_promo_appl_enum_id" :items="productConditions" label="Condición"
                      :rules="required" item-value="enum_id" item-text="description" dense>
                      <template v-slot:append-outer>
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon v-bind="attrs" v-on="on" class="mx-2" color="success"
                              @click="storeProductConditionAction(pc)">
                              mdi-floppy
                            </v-icon>
                          </template>
                          <span>Guardar condición de producto</span>
                        </v-tooltip>
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon v-bind="attrs" v-on="on" class="mx-2" color="red"
                              @click="deleteProductConditionAction(pc)">mdi-delete-forever</v-icon>
                          </template>
                          <span>Eliminar condición de producto</span>
                        </v-tooltip>
                      </template>
                    </v-autocomplete>
                  </v-col>
                </v-row>
              </v-col>
            </v-col>
          </v-row>
        </v-form>
        <v-col class="text-center">
          <v-btn color="default" @click="closeConditionProductsDialog">
            <v-icon>mdi-cancel</v-icon> Cerrar
          </v-btn>
        </v-col>
      </v-col>
    </v-dialog>
    <v-dialog v-model="dialogConditionCategoryAction" max-width="1000" persistent background-color="white">
      <v-col>
        <v-alert type="info" icon="mdi-dots-vertical" dense>
          Condiciones de categorías
          <div style="float:right">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-bind="attrs" v-on="on" class="mx-2" @click="addCategoriesConditionAction">mdi-plus</v-icon>
              </template>
              <span>Agregar condiciones de categorías</span>
            </v-tooltip>
          </div>
        </v-alert>
        <v-form ref="form_condition_category">
          <v-row>
            <v-col v-for="(cc, x) in categoriesCondition" :key="x" cols="6">
              <v-col cols="12">
                <v-row>
                  <v-col cols="6">
                    <v-autocomplete v-model="cc.new_product_category_id" :items="categories" label="Categoría"
                      :rules="required" item-value="product_category_id" item-text="description" dense />
                  </v-col>
                  <v-col cols="6">
                    <v-autocomplete v-model="cc.product_promo_appl_enum_id" :items="categoryConditions" label="Condición"
                      :rules="required" item-value="enum_id" item-text="description" dense>
                      <template v-slot:append-outer>
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon v-bind="attrs" v-on="on" class="mx-2" color="success"
                              @click="storeCategoryConditionAction(cc)">
                              mdi-floppy
                            </v-icon>
                          </template>
                          <span>Guardar condición de categoría</span>
                        </v-tooltip>
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon v-bind="attrs" v-on="on" class="mx-2" color="red"
                              @click="deleteCategoryConditionAction(cc)">mdi-delete-forever</v-icon>
                          </template>
                          <span>Eliminar condición de categoría</span>
                        </v-tooltip>
                      </template>
                    </v-autocomplete>
                  </v-col>
                </v-row>
              </v-col>
            </v-col>
          </v-row>
        </v-form>
        <v-col class="text-center">
          <v-btn color="default" @click="closeConditionCategoryDialog">
            <v-icon>mdi-cancel</v-icon> Cerrar
          </v-btn>
        </v-col>
      </v-col>
    </v-dialog>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
import Vue from "vue";
import Cupones from "./CuponesPromo.vue";
import { deprecationHandler } from "moment";

export default {
  name: "PromotionComponent",
  components: {
    Cupones,
  },
  data: () => ({
    headers: [
      { text: "Código", value: "product_promo_id", align: "start" },
      { text: "Nombre", value: "promo_name", align: "start" },
      { text: "Requiere código", value: "require_code", align: "start" },
      { text: "Reglas", value: "data-table-expand", align: "center" },
      { text: "Acciones", value: "actions", align: "center" },
    ],

    headersTiendas: [
      { text: "Código", value: "product_store_id", align: "start" },
      { text: "Tienda", value: "store_name", align: "start" },
      { text: "Desde", value: "from_date", align: "start" },
      { text: "Hasta", value: "thru_date", align: "center" },
      { text: "Acciones", value: "actions_tienda", align: "center" },
    ],
    expanded: [],
    search: null,
    dataTable: [],
    totalItems: 1,
    currentPage: 1,
    itemsPerPage: 50,
    itemsPerPageCupones: 50,
    dialog: false,
    dialogConditions: false,
    dialogPromotion: false,
    dialogCrearCupones: false,
    dialogConditionProductCondition: false,
    dialogConditionCategoryCondition: false,
    dialogConditionProductAction: false,
    dialogConditionCategoryAction: false,
    condition: null,
    productPromoId: null,
    productPromoCondSeqId: null,
    productPromoActionSeqId: null,
    promotion: {},
    rule: {},
    productPromoConditions: [],
    productPromoActions: [],
    productsContdition: [],
    categoryConditions: [],
    categoriesCondition: [],
    conditions: [],
    operators: [],
    products: [],
    categories: [],
    actions: [],
    productConditions: [],
    productStores: [],
    required: [(v) => !!v || "El campo es requerido"],
    requiredMin: [
      (v) => !!v || "El campo es requerido",
      (v) => (!!v && v > 0) || "Debe escribir un número mayor a cero",
    ],
    productStoreId: "",
    fechaDesde: null,
    fechaHasta: null,
    tiendasPromo: [],
    tab: null,
    cuponesPromo: [],
    requiereCodigo: false,
    headersCupones: [
      { text: "Código", value: "product_promo_code_id", align: "start" },
      {
        text: "Ingresado por el usuario",
        value: "user_entered",
        align: "start",
      },
      {
        text: "Confirmar un código",
        value: "require_email_or_party",
        align: "start",
      },
      { text: "Límite por cupón", value: "use_limit_per_code", align: "start" },
      {
        text: "Límite por cliente",
        value: "use_limit_per_customer",
        align: "start",
      },
      {
        text: "Desde",
        value: "from_date",
        align: "start",
      },
      {
        text: "Hasta",
        value: "thru_date",
        align: "start",
      },
      { text: "Opciones", value: "opciones_cupon", align: "start" },
    ],
    cupon: {
      product_promo_code_id: "",
      product_promo_id: "",
      user_entered: "N",
      require_email_or_party: "N",
      use_limit_per_code: 0,
      use_limit_per_customer: 0,
      from_date: "",
      thru_date: "",
      cantidad_cupones: 1,
    },
    ingresadoPorUusuario: false,
    cuponRequiereCodigo: false,
    ingresoManual: false,
    cuponEdit: false,
  }),
  computed: {
    ...mapState("master", ["loadingTable", "tenantId"]),
    ruleMaxNumCupones() {
      if (this.cupon.cantidad_cupones > 500 || this.cupon.cantidad_cupones == 0) {
        return [false || "Ingrese un número entre 1 y 500"]
      }
    },
    ruleFechaFinCupon() {

      if (
        !(this.cupon.from_date == "" || this.cupon.from_date == null) &&
        !(this.cupon.thru_date == "" || this.cupon.thru_date == null)
      ) {

        if (new Date(this.cupon.thru_date) <= new Date(this.cupon.from_date)) {
          return [false || "La fecha final debe ser mayor a la de inicio"];
        }



      }

    },
  },
  watch: {
    requiereCodigo: function (val) {
      if (val == false) {
        this.promotion.require_code = "N";
      } else {
        this.promotion.require_code = "Y";
      }
    },
    ingresoManual: function (val) {
      if (val == false) {
        this.cupon.product_promo_code_id = "";
      } else {
        this.cupon.cantidad_cupones = 1;
      }
    },
    cuponRequiereCodigo: function (val) {
      if (val == true) {
        this.cupon.require_email_or_party = "Y";
      } else {
        this.cupon.require_email_or_party = "N";
      }
    },
    ingresadoPorUusuario: function (val) {
      if (val == true) {
        this.cupon.user_entered = "Y";
      } else {
        this.cupon.user_entered = "N";
      }
    },

  },
  methods: {
    ...mapMutations("master", ["setUrl", "setLoadingTable", "setTitleToolbar"]),

    ...mapActions("master", ["requestApi", "alertNotification"]),

    getPromotions() {
      this.setLoadingTable(true);
      this.setUrl("lista-promocion");
      this.dataTable = [];
      this.requestApi({
        method: "GET",
        data: {},
      })
        .then((res) => {
          //console.log(res);
          this.totalItems = res.data.page_count;
          this.dataTable = res.data._embedded.lista_promocion;
          this.tiendasPromo = [];
        })
        .then(() => {
          this.setLoadingTable(false);
        });
    },

    editRule(product_promo_id, rule = {}) {
      this.dialog = true;
      this.productPromoId = product_promo_id;
      this.rule = rule;
    },

    editConditionAction(promoId, rule) {
      this.setUrl(`lista-promocion/${promoId}`);
      this.rule = rule;
      this.productPromoId = promoId;
      this.requestApi({
        method: "GET",
        data: {
          typeFetch: "getProductPromoCond",
          ...rule,
        },
      }).then((res) => {
        const {
          productPromoConditions,
          conditions,
          operators,
          products,
          categories,
          productPromoActions,
          actions,
        } = res.data;

        this.operators = operators;
        this.conditions = conditions;
        this.productPromoConditions = productPromoConditions;
        this.products = products;
        this.categories = categories;
        this.productPromoActions = productPromoActions;
        this.actions = actions;
        this.dialogConditions = true;
      });

      /* this.setUrl(`listar-promocion/0`)

            this.requestApi({
        method: 'GET',
        data: {
                    typeFetch: 'getProductPromoRule',
                    product_promo_rule_id,
                    product_promo_id
                }
      }).then(res =>{

      }) */
    },

    closeConditionDialog() {
      this.dialogConditions = false;
      this.condition = null;
    },

    closeRuleDialog() {
      this.dialog = false;
      this.rule = {};
    },

    closeConditionProductsDialog() {
      this.productsContdition = [];
      this.dialogConditionProductCondition = false;
      this.dialogConditionProductAction = false;
    },

    closeConditionCategoryDialog() {
      this.categoriesCondition = [];
      this.dialogConditionCategoryCondition = false;
      this.dialogConditionCategoryAction = false;
    },

    storeRule() {
      if (!this.$refs.form_rule.validate()) return false;

      this.setUrl("crea-promocion");

      this.requestApi({
        method: "POST",
        data: {
          typeStore: "storeRule",
          product_promo_id: this.productPromoId,
          ...this.rule,
        },
      }).then((res) => {
        this.alertNotification({
          param: {
            html: res.data.res.msg,
          },
        });
        this.getPromotions();

        /*let objPp = this.dataTable.find(e => e.product_promo_id == this.productPromoId)
                let indexPP = this.dataTable.indexOf(objPp)

                let objRule = this.dataTable[indexPP].rules.find(e => e.product_promo_rule_id == objPp.product_promo_rule_id)
                let indexRule = this.dataTable[indexPP].rules.indexOf(objRule)

                this.dataTable[indexPP].rules.push({

                })*/

        this.dialog = false;
      });
    },

    addConditionRule() {
      let orderProductPromoConditions = this.productPromoConditions.sort(
        (a, b) => {
          return b.product_promo_cond_seq_id > a.product_promo_cond_seq_id;
        }
      );

      this.productPromoConditions.unshift({
        cond_value: null,
        input_param_enum_id: null,
        operator_enum_id: null,
        product_promo_cond_seq_id: orderProductPromoConditions.length
          ? String(
            parseInt(
              orderProductPromoConditions[0].product_promo_cond_seq_id
            ) + 1
          ).padStart(2, "0")
          : "01",
        product_promo_id: this.productPromoId,
        product_promo_rule_id: this.rule.product_promo_rule_id,
      });
    },

    addActionRule() {
      let orderProductPromoActions = this.productPromoActions.sort((a, b) => {
        return b.product_promo_action_seq_id > a.product_promo_action_seq_id;
      });

      this.productPromoActions.unshift({
        amount: null,
        description: null,
        order_adjustment_type_id: "PROMOTION_ADJUSTMENT",
        party_id: null,
        product_id: null,
        product_promo_action_enum_id: null,
        product_promo_action_seq_id: orderProductPromoActions.length
          ? String(
            parseInt(
              orderProductPromoActions[0].product_promo_action_seq_id
            ) + 1
          ).padStart(2, "0")
          : "01",
        product_promo_id: this.productPromoId,
        product_promo_rule_id: this.rule.product_promo_rule_id,
        quantity: null,
        service_name: null,
        use_cart_quantity: "N",
      });
    },

    storeConditionRule(condition) {
      //console.log(condition);
      this.setUrl("crea-promocion");

      this.requestApi({
        method: "POST",
        data: {
          typeStore: "storeConditionRule",
          ...condition,
        },
      }).then((res) => {
        this.alertNotification({
          param: {
            html: res.data.res.msg,
          },
        });
      });
    },

    editProductsCondition(ppc) {
      this.setUrl(`lista-promocion/0`);
      this.productPromoCondSeqId = ppc.product_promo_cond_seq_id;
      this.requestApi({
        method: "GET",
        data: {
          typeFetch: "getProductsCondition",
          ...ppc,
        },
      }).then((res) => {
        //console.log(res);
        this.productsContdition = res.data.products;
        this.dialogConditionProductCondition = true;
        this.productConditions = res.data.productConditions;
      });
    },

    editCategoriesCondition(ppc) {
      this.setUrl(`lista-promocion/0`);
      this.productPromoCondSeqId = ppc.product_promo_cond_seq_id;
      this.requestApi({
        method: "GET",
        data: {
          typeFetch: "getCategoriesCondition",
          ...ppc,
        },
      }).then((res) => {
        //console.log(res);
        this.dialogConditionCategoryCondition = true;
        this.categoriesCondition = res.data.categories;
        this.dialogCategorytConditionAction = true;
        this.categoryConditions = res.data.categoryConditions;
      });
    },

    addCategoriesCondition() {
      //console.log(this.rule);
      this.categoriesCondition.unshift({
        old_product_category_id: null,
        new_product_category_id: null,
        product_promo_appl_enum_id: null,
        product_promo_cond_seq_id: this.productPromoCondSeqId,
        product_promo_rule_id: this.rule.product_promo_rule_id,
        product_promo_id: this.rule.product_promo_id,
      });
    },

    storeActionRule(action) {
      this.setUrl("crea-promocion");

      this.requestApi({
        method: "POST",
        data: {
          typeStore: "storeActionRule",
          ...action,
        },
      }).then((res) => {
        this.alertNotification({
          param: {
            html: res.data.res.msg,
          },
        });
      });
    },

    addProductCondition() {
      this.productsContdition.unshift({
        old_product_id: null,
        new_product_id: null,
        product_promo_appl_enum_id: null,
        product_promo_cond_seq_id: this.productPromoCondSeqId,
        product_promo_rule_id: this.rule.product_promo_rule_id,
        product_promo_id: this.rule.product_promo_id,
      });
    },

    addProductConditionAction() {
      this.productsContdition.unshift({
        old_product_id: null,
        new_product_id: null,
        product_promo_appl_enum_id: null,
        product_promo_action_seq_id: this.productPromoActionSeqId,
        product_promo_rule_id: this.rule.product_promo_rule_id,
        product_promo_id: this.rule.product_promo_id,
      });
    },

    addCategoriesConditionAction() {
      //console.log(this.rule);
      this.categoriesCondition.unshift({
        old_product_category_id: null,
        new_product_category_id: null,
        product_promo_appl_enum_id: null,
        product_promo_action_seq_id: this.productPromoActionSeqId,
        product_promo_rule_id: this.rule.product_promo_rule_id,
        product_promo_id: this.rule.product_promo_id,
      });
    },

    editProductsActions(ppa) {
      //sconsole.log(ppa);
      this.setUrl(`lista-promocion/0`);
      this.productPromoActionSeqId = ppa.product_promo_action_seq_id;
      this.requestApi({
        method: "GET",
        data: {
          typeFetch: "getProductsAction",
          ...ppa,
        },
      }).then((res) => {
        //console.log(res);
        this.productsContdition = res.data.products;
        this.dialogConditionProductAction = true;
        this.productConditions = res.data.productConditions;
      });
    },

    editCategoriesAction(ppa) {
      this.setUrl(`lista-promocion/0`);
      this.productPromoActionSeqId = ppa.product_promo_action_seq_id;
      this.requestApi({
        method: "GET",
        data: {
          typeFetch: "getCategoriesAction",
          ...ppa,
        },
      }).then((res) => {
        //console.log(res);
        this.dialogConditionCategoryAction = true;
        this.categoriesCondition = res.data.categories;
        this.categoryConditions = res.data.categoryConditions;
      });
    },

    deleteActionRule(action) {
      let index = this.productPromoActions.indexOf(action);

      Vue.swal({
        html: "Está seguro de eliminar esta acción?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Aceptar",
        cancelButtonText: "Cerrar",
        ...this.paramAlertQuestion,
      }).then((result) => {
        if (result.isConfirmed) {
          this.setUrl("crea-promocion");
          this.requestApi({
            method: "DELETE",
            data: {
              typeDelete: "deleteActionRule",
              ...action,
            },
          })
            .then((res) => {
              console.log(res);

              this.alertNotification({
                param: {
                  html: res.data.detail,
                },
              });
              this.productPromoActions.splice(index, 1);
            })
            .then(() => { });
        }
      });
    },

    deleteConditionRule(condition) {
      let index = this.productPromoConditions.indexOf(condition);

      Vue.swal({
        html: "Está seguro de eliminar esta condición?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Aceptar",
        cancelButtonText: "Cerrar",
        ...this.paramAlertQuestion,
      }).then((result) => {
        if (result.isConfirmed) {
          this.setUrl("crea-promocion");
          this.requestApi({
            method: "DELETE",
            data: {
              typeDelete: "deleteConditionRule",
              ...condition,
            },
          })
            .then((res) => {
              //console.log(res);

              this.alertNotification({
                param: {
                  html: res.data.detail,
                },
              });
              this.productPromoConditions.splice(index, 1);
            })
            .then(() => { });
        }
      });
    },

    deleteProductAction() { },

    editPromotion(promotion = {}) {
      this.promotion = promotion;
      this.cupon.product_promo_id = this.promotion.product_promo_id;
      this.cargarTiendasPromo(promotion.product_promo_id);
      this.dialogPromotion = true;
    },

    closeDialogPromotion() {
      this.promotion = {};
      this.dialogPromotion = false;
    },

    storePromotion() {
      if (!this.$refs.form_promotion.validate()) return false;

      this.setUrl("crea-promocion");
      console.log(this.promotion);
      this.requestApi({
        method: "POST",
        data: {
          typeStore: "storePromotion",
          ...this.promotion,
        },
      }).then((res) => {
        this.alertNotification({
          param: {
            html: res.data.res.msg,
          },
        });
        this.getPromotions();
        this.dialogPromotion = false;
      });
    },

    deleteRule(product_promo_id, rule) {
      let objPp = this.dataTable.find(
        (e) => e.product_promo_id == product_promo_id
      );
      let indexPP = this.dataTable.indexOf(objPp);

      let objRule = this.dataTable[indexPP].rules.find(
        (e) => e.product_promo_rule_id == objPp.product_promo_rule_id
      );
      let indexRule = this.dataTable[indexPP].rules.indexOf(objRule);

      Vue.swal({
        html: "Está seguro de eliminar esta regla?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Aceptar",
        cancelButtonText: "Cerrar",
        ...this.paramAlertQuestion,
      }).then((result) => {
        if (result.isConfirmed) {
          this.setUrl("crea-promocion");
          this.requestApi({
            method: "DELETE",
            data: {
              typeDelete: "deleteRule",
              product_promo_id,
              ...rule,
            },
          }).then((res) => {
            console.log(res);

            this.alertNotification({
              param: {
                html: res.data.detail,
              },
            });
            this.dataTable[indexPP].rules.splice(indexRule, 1);
          });
        }
      });
    },

    deletePromotion(promotion) {
      Vue.swal({
        html: "Está seguro de eliminar esta promoción?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Aceptar",
        cancelButtonText: "Cerrar",
        ...this.paramAlertQuestion,
      }).then((result) => {
        if (result.isConfirmed) {
          this.setUrl("crea-promocion");
          this.requestApi({
            method: "DELETE",
            data: {
              typeDelete: "deletePromotion",
              ...promotion,
            },
          }).then((res) => {
            console.log(res);

            this.alertNotification({
              param: {
                html: res.data.detail,
              },
            });
            let objPp = this.dataTable.find(
              (e) => e.product_promo_id == promotion.product_promo_id
            );
            let index = this.dataTable.indexOf(objPp);
            this.dataTable.splice(index, 1);
          });
        }
      });
    },

    deleteProductCondition(product) {
      console.log(product);
      Vue.swal({
        html: "Está seguro de eliminar esta condición de producto?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Aceptar",
        cancelButtonText: "Cerrar",
        ...this.paramAlertQuestion,
      }).then((result) => {
        if (result.isConfirmed) {
          this.setUrl("crea-promocion");
          this.requestApi({
            method: "DELETE",
            data: {
              typeDelete: "deleteProductCondition",
              ...product,
            },
          }).then((res) => {
            console.log(res);

            this.alertNotification({
              param: {
                html: res.data.detail,
              },
            });
            let obj = this.productsContdition.find(
              (e) =>
                e.product_promo_cond_seq_id ==
                product.product_promo_cond_seq_id &&
                e.old_product_id == product.old_product_id
            );
            let index = this.productsContdition.indexOf(obj);
            this.productsContdition.splice(index, 1);
          });
        }
      });
    },

    deleteCategoryCondition(category) {
      console.log(category);
      Vue.swal({
        html: "Está seguro de eliminar esta condición de categoría?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Aceptar",
        cancelButtonText: "Cerrar",
        ...this.paramAlertQuestion,
      }).then((result) => {
        if (result.isConfirmed) {
          this.setUrl("crea-promocion");
          this.requestApi({
            method: "DELETE",
            data: {
              typeDelete: "deleteCategoryCondition",
              ...category,
            },
          }).then((res) => {
            console.log(res);

            this.alertNotification({
              param: {
                html: res.data.detail,
              },
            });
            let obj = this.categoriesCondition.find(
              (e) =>
                e.product_promo_cond_seq_id ==
                category.product_promo_cond_seq_id &&
                e.old_product_category_id == category.old_product_category_id
            );
            let index = this.categoriesCondition.indexOf(obj);
            this.categoriesCondition.splice(index, 1);
          });
        }
      });
    },

    storeProductCondition(productCondition) {
      this.setUrl("crea-promocion");

      this.requestApi({
        method: "POST",
        data: {
          typeStore: "storeProductCondition",
          ...productCondition,
        },
      }).then((res) => {
        this.alertNotification({
          param: {
            html: res.data.res.msg,
          },
        });
        this.getPromotions();
        this.dialogPromotion = false;
      });
    },

    storeCategoryCondition(categoryCondition) {
      this.setUrl("crea-promocion");

      this.requestApi({
        method: "POST",
        data: {
          typeStore: "storeCategoryCondition",
          ...categoryCondition,
        },
      }).then((res) => {
        this.alertNotification({
          param: {
            html: res.data.res.msg,
          },
        });
        this.getPromotions();
        this.dialogPromotion = false;
      });
    },

    storeProductConditionAction(productCondition) {
      this.setUrl("crea-promocion");

      this.requestApi({
        method: "POST",
        data: {
          typeStore: "storeProductConditionAction",
          ...productCondition,
        },
      }).then((res) => {
        this.alertNotification({
          param: {
            html: res.data.res.msg,
          },
        });
        this.getPromotions();
        this.dialogPromotion = false;
      });
    },

    deleteProductConditionAction(product) {
      console.log(product);
      Vue.swal({
        html: "Está seguro de eliminar esta condición de producto?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Aceptar",
        cancelButtonText: "Cerrar",
        ...this.paramAlertQuestion,
      }).then((result) => {
        if (result.isConfirmed) {
          this.setUrl("crea-promocion");
          this.requestApi({
            method: "DELETE",
            data: {
              typeDelete: "deleteProductConditionAction",
              ...product,
            },
          }).then((res) => {
            console.log(res);

            this.alertNotification({
              param: {
                html: res.data.detail,
              },
            });
            let obj = this.productsContdition.find(
              (e) =>
                e.product_promo_cond_seq_id ==
                product.product_promo_cond_seq_id &&
                e.old_product_id == product.old_product_id
            );
            let index = this.productsContdition.indexOf(obj);
            this.productsContdition.splice(index, 1);
          });
        }
      });
    },

    storeCategoryConditionAction(categoryCondition) {
      this.setUrl("crea-promocion");

      this.requestApi({
        method: "POST",
        data: {
          typeStore: "storeCategoryConditionAction",
          ...categoryCondition,
        },
      }).then((res) => {
        this.alertNotification({
          param: {
            html: res.data.res.msg,
          },
        });
        this.getPromotions();
        this.dialogPromotion = false;
      });
    },

    deleteCategoryConditionAction(category) {
      console.log(category);
      Vue.swal({
        html: "Está seguro de eliminar esta condición de categoría?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Aceptar",
        cancelButtonText: "Cerrar",
        ...this.paramAlertQuestion,
      }).then((result) => {
        if (result.isConfirmed) {
          this.setUrl("crea-promocion");
          this.requestApi({
            method: "DELETE",
            data: {
              typeDelete: "deleteCategoryConditionAction",
              ...category,
            },
          }).then((res) => {
            console.log(res);

            this.alertNotification({
              param: {
                html: res.data.detail,
              },
            });
            let obj = this.categoriesCondition.find(
              (e) =>
                e.product_promo_cond_seq_id ==
                category.product_promo_cond_seq_id &&
                e.old_product_category_id == category.old_product_category_id
            );
            let index = this.categoriesCondition.indexOf(obj);
            this.categoriesCondition.splice(index, 1);
          });
        }
      });
    },

    agregarTienda() {
      this.setUrl("tienda-promo");

      this.requestApi({
        method: "POST",
        data: {
          accion: "crear",
          product_promo_id: this.promotion.product_promo_id,
          product_store_id: this.productStoreId,
          from_date: this.fechaDesde,
          thru_date: this.fechaHasta,
        },
      }).then((res) => {
        this.alertNotification({
          param: {
            html: res.data.msg,
          },
        });
        this.cargarTiendasPromo(this.promotion.product_promo_id);

        // this.dialogPromotion=false
      });
    },

    borrarTienda(tiendaId) {
      this.setUrl("tienda-promo");

      this.requestApi({
        method: "POST",
        data: {
          accion: "borrar",
          product_promo_id: this.promotion.product_promo_id,
          product_store_id: tiendaId,
        },
      }).then((res) => {
        this.alertNotification({
          param: {
            html: res.data.msg,
          },
        });

        this.cargarTiendasPromo(this.promotion.product_promo_id);
        //this.dialogPromotion=false
      });
    },

    cargarTiendasPromo(promoId) {
      this.setLoadingTable(true);
      this.setUrl("tienda-promo/" + promoId);
      this.tiendasPromo = [];
      this.requestApi({
        method: "GET",
        data: {},
      })
        .then((res) => {
          this.tiendasPromo = res.data.tiendas;
          this.cuponesPromo = res.data.cupones;
          console.log(this.cuponesPromo);
        })
        .then(() => {
          this.setLoadingTable(false);
        });
    },

    cargarTiendas() {
      this.setLoadingTable(true);
      this.setUrl("tienda-promo");
      this.dataTable = [];

      this.requestApi({
        method: "GET",
        data: {},
      })
        .then((res) => {
          this.productStores = res.data._embedded.tienda_promo;
        })
        .then(() => {
          this.setLoadingTable(false);
        });
    },

    borrarCupon(item) {
      this.setLoadingTable(true);
      this.setUrl("cupon-promo");

      this.requestApi({
        method: "POST",
        data: {
          accion: "borrarPromoCode",
          product_promo_code_id: item.product_promo_code_id,
        },
      })
        .then((res) => {
          //this.productStores = res.data._embedded.tienda_promo;
          this.cargarTiendasPromo(this.promotion.product_promo_id);
          this.setLoadingTable(false);
        })
        .then(() => {
          this.setLoadingTable(false);
        })
        .catch(() => {
          this.setLoadingTable(false);
        });
    },
    crearCupon() {
      this.cuponEdit = false;
      this.ingresoManual = false;
      this.ingresadoPorUusuario = false;
      this.cuponRequiereCodigo = false;
      this.cupon = {
        product_promo_code_id: "",
        product_promo_id: this.promotion.product_promo_id,
        user_entered: "N",
        require_email_or_party: "N",
        use_limit_per_code: 0,
        use_limit_per_customer: 0,
        from_date: "",
        thru_date: "",
        cantidad_cupones: 1,
      };

      this.dialogCrearCupones = true
    },
    editarCupon(item) {
      this.cuponEdit = true;
      this.ingresoManual = true;
      this.setUrl("cupon-promo/" + item.product_promo_code_id);


      this.requestApi({
        method: "POST",
        data: {
          accion: "editarCupon",
          product_promo_code_id: item.product_promo_code_id,
        },
      })
        .then((res) => {

          this.cupon = res.data.detail;

          this.requiereCodigo = false;
          this.ingresadoPorUusuario = false;

          if (res.data.detail.user_entered == "Y") {
            this.ingresadoPorUusuario = true
          }

          if (res.data.detail.require_email_or_party == "Y") {
            this.cuponRequiereCodigo = true;
          }

          this.cupon.from_date = res.data.detail.from_date.substring(0, 10)
          this.cupon.thru_date = res.data.detail.thru_date.substring(0, 10)

          this.dialogCrearCupones = true

          //console.log(res.data.detail);
        })
        .catch(() => {

        });

    },
    guardarCupones() {

      if (!this.$refs.formCupon.validate()) {
        return false;
      }
      this.setLoadingTable(true);
      this.setUrl("cupon-promo");
      this.cupon.editar = null
      if (this.cuponEdit == true) {
        this.cupon.editar = true;
      }
      this.requestApi({
        method: "POST",
        data: {
          accion: "guardarPromoCode",
          ...this.cupon,
        },
      })
        .then((res) => {
          //this.productStores = res.data._embedded.tienda_promo;
          this.cargarTiendasPromo(this.promotion.product_promo_id);
          this.setLoadingTable(false);
          this.dialogCrearCupones = false;
        })
        .then(() => {
          this.setLoadingTable(false);
          this.dialogCrearCupones = false;
        })
        .catch(() => {
          this.setLoadingTable(false);
        });
    },


  },


  created() {
    this.getPromotions();
    this.cargarTiendas();
  },
};
</script>

<style>
.v-dialog {
  background: white;
}
</style>
